<template>
    <div class="contentContainer h-full overflow-auto">
        <div class="contentContainer w-full rounded-lg h-full">

            <NuxtLayout class="p-4 min-h-[60rem] sm:min-h-0 !bg-contain !bg-right-top !bg-no-repeat" :style="background?`background: url(${background})`:''" :name="pageLayout">
          
            <!-- Nuxtlayout is used for page layouts - hopefully in the future -->
            <!-- <NuxtLayout :name="layout" v-for="content in pageContents">   -->
                <div class="" v-for="content in pageContents">

                <!--     <adminEditWrapper :content="content" v-if="adminModeActive">
                        <component :key="content.content_id"
                            v-if="allowedTemplates.includes(content.component.replace('.jsp', '').toLowerCase())"
                            :is="content.component.replace('.jsp', '').toLowerCase()"
                            :content="content.longDescription != null ? content.longDescription : 'longDescription does not exist'">
                        </component>
                    </adminEditWrapper> -->

                        <component :key="content.content_id"
                        v-if="allowedTemplates.includes(content.component.replace('.jsp', '').toLowerCase()) && !adminModeActive"
                        :is="content.component.replace('.jsp', '').toLowerCase()" :content="content.longDescription">
                        </component>
    
                </div>

            
            </NuxtLayout>
        </div>
    </div>
</template>

<script lang="ts">
import type { PageContents } from '~/types/backendtypes';
import type { LayoutKey } from '#build/types/layouts';
import { useAdminStore } from '@/stores/admin/adminStore';
import {mapState} from 'pinia' 
import { useAPI } from '~/composables/useApi';

export default defineNuxtComponent({
    name: 'frontpage',
    data(){
        return {
            allowedTemplates: [] as string[],
            pageContents: [] as PageContents[],
            background: '' as string,
            pageLayout: '' as LayoutKey,
        }
    },
    async setup() {
        const config = useRuntimeConfig();
        const api = useAPI();
        const reqUrl = useRequestURL();
        
        const allowedTemplates = ["simplecontentelementhtml4", "contact"]
        const route = useRoute();
        
        // SERVERROUTE 
        const data = await api.getPage('/hem');
        const {metaData, pageContents, pageProperties} = data;

        const MEASURE_ID = pageProperties.find(x => x.name == 'googleanalytics')?.property_value
        //GTAG
        useHead({
            script: [{
                async: true,
                src: `https://www.googletagmanager.com/gtag/js?id=${MEASURE_ID}`
            },
            `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${MEASURE_ID}');`],
        })
    

        const pageName = metaData.name;
        ////

        //// LAYOUT
        const { $pageLayout } = useNuxtApp();
        const pageLayout = await $pageLayout(metaData.pageTemplate)
        //
        
        const {$getPageProperty} = useNuxtApp();
        const backgroundImg = $getPageProperty("background", pageProperties);
        const background = backgroundImg ? `${config.public.WEBCMS_ENV != 'production' ? config.public.API_BASE+'/'+ backgroundImg?.property_value : backgroundImg?.property_value}` : '';

        //// Meta
        const {$seoObjectCreator} = useNuxtApp();
        const seoObj = $seoObjectCreator(metaData);

        useSeoMeta(seoObj);
        console.log(reqUrl)
        useHead({
            link: [
                {
                    rel: 'canonical',
                    href: `https://${reqUrl.host}${reqUrl.pathname}`
                },
            ],
        })
        //// end Meta

        return { pageContents, allowedTemplates, pageLayout, pageName, background }

        
    },
    computed: {
        ...mapState(useAdminStore, ["toolbar", "adminModeActive"])
    }

})
</script>